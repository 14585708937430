import React, { useEffect, useState, useCallback, useRef } from 'react'

import PropTypes from 'prop-types'
import ContentLoader from 'react-content-loader'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router'
import { useTranslation } from 'react-i18next'
import Compressor from 'compressorjs'
import moment from 'moment'
import map from 'lodash/map'
import times from 'lodash/times'
import isEmpty from 'lodash/isEmpty'
import { toast } from 'react-toastify'
import CircularProgress from '@material-ui/core/CircularProgress'
import colors from 'Assets/colors'
import { ReactComponent as FileIcon } from 'Assets/Icons/File.svg'
import { ReactComponent as AttachmentIcon } from 'Assets/Icons/Attachment.svg'
import { ReactComponent as EnvelopeIcon } from 'Assets/Icons/Envelope.svg'
import { ReactComponent as TrashIcon } from 'Assets/Icons/Trash.svg'
import { TICKET_STATUS_COLOR, TICKET_STATUS, COMPRESSOR_OPTIONS } from 'Config/constants'
import { TicketActions } from 'Redux/ticket'
import {
  selectTicket,
  selectIsLoadingTikets,
  selectIsLoadingTiketComment,
  selectCenterId,
} from 'Redux/ticket/selectors'
import { postNoConformitiesAttachment } from 'Services/api'
import { TextInput, TicketStatusIcon } from 'Components/atoms'

import {
  Container,
  SingleTicketContainer,
  SingleTicketHeader,
  SingleTicketInfo,
  SingleTicketContent,
  ProviderInfoContainer,
  ProviderName,
  TicketInfoAuxText,
  TicketInfoContainer,
  TicketStatusContainer,
  SingleTicketMainText,
  TicketStatusInfoContainer,
  TicketStatusCombo,
  SingleTicketConversationContainer,
  StaticSingleTicketInfo,
  StaticSingleTicketUserName,
  StaticSingleTicketComment,
  StaticSingleTicketCreated,
  StaticSingleTicketInfoTop,
  SingleTicketAttachmentsContainer,
  SingleTicketAttachment,
  SingleTicketAttachmentWithDelete,
  TicketSendForm,
  TicketSendFormActions,
  AttachButtonContainer,
  SendButtonContainer,
  SingleTicketAttachmentSelf,
  TrashButtonContainer,
} from './styled'

const TicketingDetail = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { id } = useParams()
  const lastCommentRef = useRef(null)
  const inputCommentRef = useRef(null)

  const ticket = useSelector(selectTicket)
  const isLoading = useSelector(selectIsLoadingTikets)
  const isLoadingTiketComment = useSelector(selectIsLoadingTiketComment)
  const centerId = useSelector(selectCenterId)

  const [textComment, setTextComment] = useState('')
  const [files, setFiles] = useState([])
  const [fileTokens, setFileTokens] = useState([])
  const [isFetchingFile, setIsfetchingFile] = useState(false)

  useEffect(() => {
    dispatch(TicketActions.fetchTicket(id))
  }, [dispatch, id])

  useEffect(() => {
    const scrollToLastComment = () => {
      if (ticket?.ticketComments && lastCommentRef.current) {
        lastCommentRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
          inline: 'nearest',
        })
      }
    }

    const timeoutScroll = setTimeout(scrollToLastComment, 50)
    return () => clearTimeout(timeoutScroll)
  }, [ticket])

  useEffect(() => {
    const focusInputComment = () => inputCommentRef.current.focus()
    const timeout = setTimeout(focusInputComment, 100)
    return () => clearTimeout(timeout)
  }, [])

  const removeFileAtIndex = useCallback(index => {
    setFiles(prevFiles => {
      const newFiles = [...prevFiles]
      newFiles.splice(index, 1)
      return newFiles
    })

    setFileTokens(prevFileTokens => {
      const newFileTokens = [...prevFileTokens]
      newFileTokens.splice(index, 1)
      return newFileTokens
    })
  }, [])

  const sendComment = (comment, uploads) => {
    dispatch(TicketActions.addTicketComment({ id, comment, uploads }))
    setTextComment('')
    setFiles([])
    setFileTokens([])
  }

  const handleOnSendComment = e => {
    e.preventDefault()

    if (!textComment) {
      return
    }

    sendComment(textComment, fileTokens)
  }

  const handleChangeTextComment = e => setTextComment(e.target.value)

  const handleClickAttachment = acceptedFile => {
    setIsfetchingFile(true)
    const spliType = acceptedFile && acceptedFile[0] ? acceptedFile[0].type.split('/') : []
    if (spliType.length > 0 && spliType[0].includes('image')) {
      // eslint-disable-next-line no-new
      new Compressor(acceptedFile[0], {
        ...COMPRESSOR_OPTIONS,
        success(result) {
          setFiles(prevFiles => [...prevFiles, result])
          postNoConformitiesAttachment(centerId, result)
            .then(response => {
              setIsfetchingFile(false)
              setFileTokens(prevFileTokens => [...prevFileTokens, response.token])
            })
            .catch(() => {
              setIsfetchingFile(false)
              toast.error(t('ticketingUploadFileError'), {
                hideProgressBar: true,
              })
              removeFileAtIndex(files.length)
            })
        },
        error(err) {
          toast.error(err, { hideProgressBar: true })
        },
      })
    } else {
      postNoConformitiesAttachment(centerId, acceptedFile[0])
        .then(response => {
          setIsfetchingFile(false)
          setFileTokens(prevFileTokens => [...prevFileTokens, response.token])
        })
        .catch(() => {
          setIsfetchingFile(false)
          toast.error(t('ticketingUploadFileError'), {
            hideProgressBar: true,
          })
          removeFileAtIndex(files.length)
        })
    }
  }

  const handleClickRemoveAttachment = index => removeFileAtIndex(index)

  return (
    <Container>
      <SingleTicketContainer>
        <TicketHeader ticket={ticket} />
        <TicketContent ticket={ticket} />
        <SingleTicketConversationContainer>
          {isLoading && map(times(1), key => <TicketLoader key={key} />)}
          {map(ticket?.ticketComments, (comment, index) => (
            <TicketComment
              key={comment.id}
              comment={comment}
              containerRef={index === ticket?.ticketComments.length - 1 ? lastCommentRef : null}
            />
          ))}
        </SingleTicketConversationContainer>
        <CommentToolbar
          onSendComment={handleOnSendComment}
          value={textComment}
          onChange={handleChangeTextComment}
          isLoadingSend={isLoadingTiketComment}
          onAddAttachment={handleClickAttachment}
          onRemoveAttachment={handleClickRemoveAttachment}
          attachments={files}
          isLoadingFile={isFetchingFile}
          inputCommentRef={inputCommentRef}
          isDisabled={ticket.status === TICKET_STATUS.closed}
        />
      </SingleTicketContainer>
    </Container>
  )
}

const TicketHeader = ({ ticket }) => {
  const { t } = useTranslation()

  const { created, id } = ticket || {}

  return (
    <SingleTicketHeader>
      <SingleTicketInfo>{t('singleTicketHeaderId', { noConformityId: id })}</SingleTicketInfo>
      <SingleTicketInfo>
        {t('singleTicketHeaderDate', { noConformityDate: moment(created).fromNow() })}
      </SingleTicketInfo>
    </SingleTicketHeader>
  )
}

TicketHeader.defaultProps = {
  ticket: {},
}

TicketHeader.propTypes = {
  ticket: PropTypes.object,
}

const TicketContent = ({ ticket }) => {
  const { t } = useTranslation()

  const { updated, providerOrderId, subcategory, status, providerName } = ticket || {}

  const statusText = t(`ticketingStatus.${status}`)
  const statusColor = TICKET_STATUS_COLOR[status]
  const statusBorder = status === TICKET_STATUS.pending ? `1px solid ${statusColor}` : ''

  return (
    <SingleTicketContent>
      {providerName && (
        <ProviderInfoContainer>
          <ProviderName>{providerName || ''}</ProviderName>
          <TicketInfoAuxText>{`${t('order')} ${providerOrderId}`}</TicketInfoAuxText>
        </ProviderInfoContainer>
      )}
      <TicketInfoContainer>
        <SingleTicketMainText>{subcategory}</SingleTicketMainText>
      </TicketInfoContainer>
      {status && (
        <TicketStatusContainer style={{ border: statusBorder }}>
          <TicketStatusCombo>
            <TicketStatusIcon status={status} />
            <TicketStatusInfoContainer>
              <SingleTicketMainText style={{ color: statusColor }}>{statusText}</SingleTicketMainText>
              <TicketInfoAuxText>
                {t('singleTicketUpdatedDate', { ticketUpdatedDate: moment(updated).fromNow() })}
              </TicketInfoAuxText>
            </TicketStatusInfoContainer>
          </TicketStatusCombo>
        </TicketStatusContainer>
      )}
    </SingleTicketContent>
  )
}

TicketContent.defaultProps = {
  ticket: {},
}

TicketContent.propTypes = {
  ticket: PropTypes.object,
}

const TicketComment = ({ comment, containerRef }) => {
  const { userName, createdAt, body, attachments } = comment

  const handleClickAttachment = attachment => window.open(attachment.contentUrl, '_blank')

  return (
    <StaticSingleTicketInfo ref={containerRef}>
      <StaticSingleTicketInfoTop>
        <StaticSingleTicketUserName>{userName}</StaticSingleTicketUserName>
        <StaticSingleTicketCreated>{moment(createdAt).fromNow()}</StaticSingleTicketCreated>
      </StaticSingleTicketInfoTop>
      <StaticSingleTicketComment className='comment'>
        {/* eslint-disable-next-line react/no-danger */}
        <div dangerouslySetInnerHTML={{ __html: body }} />
      </StaticSingleTicketComment>
      {attachments && !isEmpty(attachments) && (
        <SingleTicketAttachmentsContainer>
          {map(attachments, (attachment, index) => (
            <SingleTicketAttachment key={index} onClick={() => handleClickAttachment(attachment)}>
              <FileIcon />
              {attachment.fileName}
            </SingleTicketAttachment>
          ))}
        </SingleTicketAttachmentsContainer>
      )}
    </StaticSingleTicketInfo>
  )
}

TicketComment.defaultProps = {
  comment: {},
  containerRef: null,
}

TicketComment.propTypes = {
  comment: PropTypes.object,
  containerRef: PropTypes.object,
}

const TicketLoader = () => {
  const { t } = useTranslation()

  return (
    <ContentLoader
      speed={2}
      width={1400}
      height={232}
      viewBox='0 0 1400 232'
      backgroundColor='#f3f3f3'
      foregroundColor='#ecebeb'
      title={t('loading')}
    >
      <rect x='18' y='60' rx='0' ry='0' width='700' height='10' />
      <rect x='18' y='18' rx='0' ry='0' width='150' height='10' />
      <rect x='18' y='110' rx='0' ry='0' width='100' height='10' />
      <rect x='400' y='110' rx='0' ry='0' width='100' height='10' />
      <rect x='18' y='142' rx='0' ry='0' width='700' height='10' />
    </ContentLoader>
  )
}

const CommentToolbar = ({
  value,
  onSendComment,
  onChange,
  isLoadingSend,
  isLoadingFile,
  onAddAttachment,
  onRemoveAttachment,
  attachments,
  inputCommentRef,
  isDisabled,
}) => {
  const { t } = useTranslation()

  return (
    <TicketSendForm onSubmit={onSendComment}>
      <TicketSendFormActions>
        <AttachButtonContainer>
          {isLoadingFile && <CircularProgress size={18} />}
          {!isLoadingFile && <AttachmentIcon />}
          <input
            disabled={isLoadingFile || isDisabled}
            style={{ display: 'none' }}
            type='file'
            onChange={e => onAddAttachment([...e.target.files])}
          />
        </AttachButtonContainer>
        <TextInput
          disabled={isLoadingSend || isLoadingFile || isDisabled}
          value={value}
          placeholder={t('singleTicketInputCommentPlaceholder')}
          onChange={onChange}
          background={colors.white}
          border='none'
          height='55px'
          borderRadius='none'
          shadow='none'
          refProp={inputCommentRef}
        />
        {!isLoadingSend && !isDisabled && (
          <SendButtonContainer type='submit' value={value}>
            <EnvelopeIcon />
          </SendButtonContainer>
        )}
        {isLoadingSend && <CircularProgress size={24} style={{ marginRight: 24 }} />}
      </TicketSendFormActions>

      {map(attachments, (attachment, index) => (
        <SingleTicketAttachmentSelf>
          <SingleTicketAttachmentWithDelete>
            <div>
              <FileIcon />
              {attachment.name}
            </div>
            <TrashButtonContainer type='button' onClick={() => onRemoveAttachment(index)} disabled={isLoadingFile}>
              <TrashIcon />
            </TrashButtonContainer>
          </SingleTicketAttachmentWithDelete>
        </SingleTicketAttachmentSelf>
      ))}
    </TicketSendForm>
  )
}

CommentToolbar.defaultProps = {
  value: '',
  onSendComment: () => {},
  onChange: () => {},
  onAddAttachment: () => {},
  onRemoveAttachment: () => {},
  isLoadingSend: false,
  isLoadingFile: false,
  attachments: [],
  inputCommentRef: null,
  isDisabled: false,
}

CommentToolbar.propTypes = {
  value: PropTypes.string,
  onSendComment: PropTypes.func,
  onChange: PropTypes.func,
  onAddAttachment: PropTypes.func,
  onRemoveAttachment: PropTypes.func,
  isLoadingSend: PropTypes.bool,
  isLoadingFile: PropTypes.bool,
  attachments: PropTypes.array,
  inputCommentRef: PropTypes.object,
  isDisabled: PropTypes.bool,
}

export default TicketingDetail
